import React, { useEffect, useRef, useState, useContext } from "react";
import SecondaryHeader from "../SecondaryHeader/SecondaryHeader";
import MenuBar from "../MenuBar/MenuBar";
import user from "../../assets/1.jpg";
import { HttpGet, HttpPost, HttpPostFile } from "../../providers/HttpClient";
import LoadingOverlay from "react-loading-overlay-ts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "../alertComponent/alertComponent";
import Axios from "axios";
import camera from "../../assets/svg/camera.svg";
import Config from "../../config/Config";

const Event: React.FC = () => {
  const env = Config.BaseUrlDev;
  const navigate = useNavigate();
  const [workLocation, setWorkLocaion] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const noteRef = useRef<HTMLTextAreaElement>(null);
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const eventId = useParams();

  const handleClick = () => {
    inputRef?.current?.click();
  };
  const uploadChoosenPhoto = async (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);

    HttpPostFile(`member/images?feature=event`, formData).then((res: any) => {
      setImage(res.data);
      setImageUrl(res.data);
    });
  };

  const alert = (message, type) => {
    setOpen(true);
    setAlertMessage(message);
    setAlertType(type);
  };

  const closeAction = async () => {
    setOpen(false);
    navigate("/home");
  };

  const manageEvent = async () => {
    if (image == "") return toast.error("Foto Harap Diisi");
    if (noteRef.current?.value == "")
      return toast.error("Keterangan Harap Diisi");
    let data = {
      location: {
        lat: latitude,
        lon: longitude,
        address: address,
      },
      picture: image,
      note: noteRef.current?.value,
    };
    setLoading(true);
    HttpPost(`member/event/${eventId.id}`, data)
      .then((res) => {
        alert("Konfirmasi Kehadiran Telah Dikirim !", true);
        setLoading(false);
        // navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        alert("Konfirmasi Kehadiran Gagal Dikirim !", false);
      });
  };

  const getLocation = async (latitude, longitude) => {
    Axios.get(
      `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=67ac5bcab2249022572803gyf594efd`
    )
      .then((res: any) => {
        setAddress(res.data.display_name);
        setLongitude(res.data.lon);
        setLatitude(res.data.lat);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          getLocation(position.coords.latitude, position.coords.longitude);
        },
        function (error) {
          toast.error("Error getting geolocation");
        }
      );
    } else {
      toast.error("Geolocation is not available in this browser.");
    }
  }, []);
  return (
    <>
      <Alert
        type={alertType}
        isOpen={open}
        closeAction={closeAction}
        message={alertMessage}
      />
      <LoadingOverlay
        className="h-full"
        active={isLoading}
        spinner
        text="Loading..."
      >
        <header className="flex items-center justify-between px-4 py-1">
          {/* <h1 className="text-base font-bold">Lembur Backup</h1> */}
        </header>
        {/* <SecondaryHeader title="Lembur" pathname="/home" /> */}
        <section id="main" className="bg-main h-full px-8 pb-24 -mt-28 z-50">
          <div className="mt-32 z-100 z-30 mb-4">
            <div
              className="flex justify-center relative bg-white m-auto mb-4"
              onClick={() => handleClick()}
            >
              <img src={imageUrl ? image : null} className="h-80" />
              <input
                type="file"
                ref={inputRef}
                accept="image/*"
                capture
                style={{ display: "none" }}
                id="photo"
                name="photo"
                // onChange={inputHandler}
                onChangeCapture={(event) => uploadChoosenPhoto(event)}
                placeholder="pilih foto"
              />
              <img
                src={camera}
                alt=""
                className="absolute z-[2] top-36 w-10 h-10"
              />
            </div>
            <div className="mb-8">
              <label htmlFor="backup" className="block text-gray-700">
                Lokasi
              </label>
              <input
                disabled
                type="text"
                className="bg-white border border-gray-300 rounded-full px-4 py-2 w-full"
                value={address}
              ></input>
            </div>
            <div className="mb-4">
              <label htmlFor="note" className="block text-gray-700">
                Keterangan
              </label>
              <textarea
                id="note"
                className="border border-gray-300 rounded-lg px-4 py-2 w-full"
                ref={noteRef}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <div
              onClick={() => manageEvent()}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full justify-center grid w-28"
            >
              Kirim
            </div>
          </div>
          <div className="flex justify-center bottom-0 mt-10">
            <p className="font-light text-sm">
              powered by: <a href="https://geocode.maps.co/">https://maps.co</a>
            </p>
          </div>
        </section>
        <MenuBar></MenuBar>
      </LoadingOverlay>
    </>
  );
};

export default Event;
